<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ukrTreasures_OrderID'])"></div>
      <CardLeftBlock
          :name="''"
          :value="''"
          :backgroundImage="'product-admin'"
          class="cus-payments"
      >
<!--        <template slot="body">-->
<!--          <div class="card-detail-left__date mt-1">-->
<!--          </div>-->
<!--        </template>-->
        <template slot="button">
          <h2 class="mt-0">{{$t('menu_heartysanListingsEdit.localization_value.value')}}</h2>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['heartysan_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.heartysanManufacturerListings" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('heartysan_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ukrTreasures_ListingToSell'])"></div>
              {{ $t('heartysan_CreateListing.localization_value.value') }}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['ukrTreasures_PleaseFillOut'])"></div>
              {{ $t('heartysan_CreateListingPleaseFill.localization_value.value') }}
            </div>
          </div>
        </template>

        <template slot="body">
          <ManufacturerListingFields
              :HL="HL"
              :edit="true"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.heartysanManufacturerListings"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
              <MainButton
                  class="order-create__footer-btn-i wfc"
                  :value="$t('common_edit.localization_value.value')"
                  @click.native="$emit('save')"
                  v-bind:class="{'disabled-btn' : $store.getters.heartysanProductsBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
import CardLeftBlock from "../../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
import MainButton from "../../../../../../../components/UI/buttons/MainButton/MainButton";
import CardRightBlock from "../../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../../UI/buttons/LinkBack/LinkBack.vue";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import ManufacturerListingFields
  from "@/components/modules/HeartysanModule/components/chunks/ManufacturerListingFields/ManufacturerListingFields";


export default {
  name: "HeartysanListingEditUser",

  components: {
    ManufacturerListingFields,
    CardLeftBlock,
    MainButton,
    CardRightBlock,
    LinkBack,
  },

  mixins: [mixinDetictingMobile],

  props: {
    HL: Object,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>

</style>

