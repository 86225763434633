<template>
  <div class="fragment">
    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_name',])"></div>
        <DefaultInput
            :label="$t('heartysan_name.localization_value.value')"
            v-bind:class="{'ui-no-valid': HL.validation.name}"
            :errorTxt="$t(`${HL.validationTranslate.name}.localization_value.value`)"
            :error="HL.validation.name"
            v-model="HL.data.name"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_category',])"></div>
        <DefaultSelect
            :label="$t('heartysan_category.localization_value.value')"
            :options="optionsCategory"
            :otherValue="'currentTranslation'"
            v-bind:class="{'ui-no-valid': HL.validation.category}"
            :errorTxt="$t(`${HL.validationTranslate.category}.localization_value.value`)"
            :error="HL.validation.category"
            :selected="HL.data.category"
            @change="changeParentCategory"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-if="HL.data.category"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_subCategory',])"></div>
        <DefaultSelect
            :label="$t('heartysan_subCategory.localization_value.value')"
            :options="optionsSubCategory"
            :otherValue="'currentTranslation'"
            v-bind:class="{'ui-no-valid': HL.validation.subCategory}"
            :errorTxt="$t(`${HL.validationTranslate.subCategory}.localization_value.value`)"
            :error="HL.validation.subCategory"
            :selected="HL.data.subCategory"
            @change="(val) => {this.HL.data.subCategory = val}"
        />
      </div>
    </div>
    <div class="custom-row">

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_country',])"></div>
        <DefaultSelect
            :label="$t('heartysan_country.localization_value.value')"
            :options="optionsCountries"
            v-bind:class="{'ui-no-valid': HL.validation.country}"
            :errorTxt="$t(`${HL.validationTranslate.country}.localization_value.value`)"
            :error="HL.validation.country"
            :selected="HL.data.country"
            :optionsLabel="'label'"
            @change="(val) => {this.HL.data.country = val}"
        />
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_quantity',])"></div>
        <DefaultInput
            :label="$t('heartysan_quantity.localization_value.value')"
            v-bind:class="{'ui-no-valid': HL.validation.quantity}"
            :errorTxt="$t(`${HL.validationTranslate.quantity}.localization_value.value`)"
            :error="HL.validation.quantity"
            v-model="HL.data.quantity"
        />
        <!--        <InputQuantity-->
        <!--            :label="$t('heartysan_quantity.localization_value.value')"-->
        <!--            v-bind:class="{'ui-no-valid': HL.validation.quantity}"-->
        <!--            :errorTxt="$t(`${HL.validationTranslate.quantity}.localization_value.value`)"-->
        <!--            :error="HL.validation.quantity"-->
        <!--            v-model="HL.data.quantity"-->
        <!--        />-->
        <!--            @change="changeValueQuantity"-->
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_price',])"></div>
        <InputSum
            :label="$t('heartysan_price.localization_value.value')"
            v-bind:class="{'ui-no-valid': HL.validation.price}"
            :error="HL.validation.price"
            :errorTxt="$t(`${HL.validationTranslate.price}.localization_value.value`)"
            :placeholder="'0'"
            :icoType="'dollar'"
            v-model="HL.data.price"
        />
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-if="!edit || (_.has(HL.data.statusDefault, 'name') && getStatusByName(HL.data.statusDefault.name).name !== HEARTYSAN_STATUSES.HEARTYSAN_STATUS_ACTIVE.value)"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_name',])"></div>
        <DefaultSelect
            :label="$t('heartysan_status.localization_value.value')"
            :options="optionsStatuses"
            :otherValue="'translation'"
            v-bind:class="{'ui-no-valid': HL.validation.status}"
            :errorTxt="$t(`${HL.validationTranslate.status}.localization_value.value`)"
            :error="HL.validation.status"
            :selected="HL.data.status"
            @change="(val) => {this.HL.data.status = val}"
        />
      </div>

<!--      <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['heartysan_delivery',])"></div>-->
<!--        <DefaultSelect-->
<!--            :label="$t('heartysan_delivery.localization_value.value')"-->
<!--            :options="[]"-->
<!--            v-bind:class="{'ui-no-valid': HL.validation.delivery}"-->
<!--            :errorTxt="$t(`${HL.validationTranslate.delivery}.localization_value.value`)"-->
<!--            :error="HL.validation.delivery"-->
<!--            :selected="HL.data.delivery"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['heartysan_delivery',])"></div>-->
<!--        <DefaultSelect-->
<!--            :label="$t('heartysan_delivery.localization_value.value')"-->
<!--            :options="[]"-->
<!--            v-bind:class="{'ui-no-valid': HL.validation.delivery}"-->
<!--            :errorTxt="$t(`${HL.validationTranslate.delivery}.localization_value.value`)"-->
<!--            :error="HL.validation.delivery"-->
<!--            v-model="HL.data.delivery"-->
<!--        />-->
<!--      </div>-->

    </div>
    <div class="custom-row">

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_description',])"></div>
        <TextareaDefault
            :label="$t('heartysan_description.localization_value.value')"
            v-bind:class="{'ui-no-valid': HL.validation.description}"
            :errorTxt="$t(`${HL.validationTranslate.description}.localization_value.value`)"
            :error="HL.validation.description"
            v-model="HL.data.description"
        />
      </div>
    </div>
    <div class="custom-row">

      <div class="custom-col custom-col--100 custom-col--md-100 logo-scoped">
        <template v-if="HL.data.Files.data.maxCountFiles - Object.keys(HL.data.Files.data.downloadFiles).length > 0"
        >
          <div >
            <DropZone class="drop-zone-bg"
                      :parentFiles="HL.data.Files.data.files"
                      :multiple="true"
                      :maxCount="HL.data.Files.data.maxCountFiles - HL.data.Files.data.downloadFiles.length"
                      :maxSize="HL.data.Files.data.maxSizeFiles"
                      @changeImg="changeImg"
            />
          </div>
        </template>

        <div class="custom-col mt-3"
             v-if="Object.keys(HL.data.Files.data.downloadFiles).length > 0"
        >
          <div class="custom-row">
            <div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
                 v-for="(item, index) in HL.data.Files.data.downloadFiles"
                 :key="index">
              <DownloadFileBlock
                  :file="item"
                  @removeFile="(id) => {removeFile(id)}"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
import {HEARTYSAN_STATUSES, HEARTYSAN_STATUSES_ARRAY} from "@/staticData/staticVariables";
import {heartysanProductsHelperMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";
// import {filterCountriesUSA} from "@/services/filterCountries";

export default {
  name: "ManufacturerListingFields",
  components: {
    DownloadFileBlock,
    DropZone, InputSum, TextareaDefault, DefaultSelect, DefaultInput},

  mixins: [routeFilter, heartysanProductsHelperMixin],

  props: {
    HL: Object,
    edit: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      categories: [],
      optionsCategory: [],
      optionsSubCategory: [],
      optionsCountries: [],
      optionsStatuses: HEARTYSAN_STATUSES_ARRAY,
      HEARTYSAN_STATUSES: HEARTYSAN_STATUSES,
    }
  },

  mounted() {

    let myQuery = this.createQueryBuilderObject()
    let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
    this.$store.dispatch('fetchHeartysanCategory', url).then(response => {
      this.categories = this.getRespPaginateData(response)
      this.optionsCategory = this._.filter(this.categories, (item) => {
        return item.parent_id === 0
      })

      if(this.HL.data.category?.id) {
        this.changeParentCategory(this.HL.data.category)
      }
    }).catch(error => this.createErrorLog(error))

    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries)
      // filterCountriesUSA(countries)
      this.optionsCountries = countries;
    })
  },

  methods: {
    changeParentCategory(val) {
      this.HL.data.category = val
      this.optionsSubCategory = this._.filter(this.categories, (item) => {
        return item.parent_id === val.id
      })
    },

    changeImg(files, maxSizeError) {
      this.HL.data.Files.setFiles(files)

      this.HL.data.Files.setFilesMaxSizeError(maxSizeError)
      console.log(this.HL.data.Files);
    },

    removeFile(id) {

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('removeUserDocument', id).then(() => {
          this.HL.removeHeartysanListingFile(id)
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },
  }
  
}
</script>

<style scoped>

</style>