<template>
  <HeartysanListingEditUser
          :HL="HL"
          @save="save({edit: true})"
  />
</template>

<script>
  import HeartysanListingEditUser from "./HeartysanListingEditUser/HeartysanListingEditUser";
  import {heartysanProductsMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";
  import {HeartysanListing} from "@/components/modules/HeartysanModule/models/HeartysanListing";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";

  export default {
    name: "HeartysanListingEdit",

    components: {
      HeartysanListingEditUser,
    },

    mixins: [heartysanProductsMixin, queryFilterMixin],

    data() {
      return {
        HL: new HeartysanListing(),
      }
    },

    mounted() {
      this.HL.setId(this.$route.params.id)

      // initialize first product
      let myQuery = this.createQueryBuilderObject()
      let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      this.$store.dispatch('fetchHeartysanCategory', url).then(response => {
        let categories = this.getRespPaginateData(response)

        this.$store.dispatch('getHeartysanProducts', this.HL.getId()).then((response) => {
          let data = this.getRespData(response)


          this.HL.setItem(data, categories)

          console.log(2342);

          this.setFiles(data.files)

        }).catch(error => this.createErrorLog(error))

      }).catch(error => this.createErrorLog(error))


    },

    methods: {

      setFiles(files) {
        if (files) {
          files.map(item => {
            this.downloadFileFromServer(item[0]).then((dataFile) => {
              this.HL.setHeartysanListingDownloadFiles(dataFile)
            })
            // this.$store.dispatch('getFileFromServer', item.id)
            //   .then((response) => {
            //     this.HL.setHeartysanListingDownloadFiles(response)
            //   })
          })
        }
      },

    }

  }
</script>

<style scoped>

</style>
